<template>
  <base-vehicle-action :vehicleId="vehicleId" checkout ref="base" />
</template>

<script>
import BaseVehicleAction from "./BaseVehicleAction.vue";
import { VEHICLE_ACCEPTANCE_OR_CHECKOUT } from "@/const/events.js";
import { CHECKOUT } from "@/const/vehicle-load-types.js";

import { mapActions } from "vuex";

export default {
  components: {
    "base-vehicle-action": BaseVehicleAction
  },
  computed: {
    vehicleId() {
      return Number(this.$route.params.vehicleId);
    }
  },
  methods: {
    ...mapActions(["vehicleLoad/loadVehicle"]),
    checkout({ loadItems, customCheckResults, sales }) {
      let vm = {
        vehicleId: this.vehicleId,
        type: CHECKOUT,
        loadItems,
        customCheckResults,
        sales
      };
      this["vehicleLoad/loadVehicle"](vm)
        .then(resp => {
          if (resp.data != null && resp.data.autoIncidents != null)
            this.$refs.base.notifyIncidents(resp.data.autoIncidents);

          this.$router.go(-1);
        })
        .catch(() => alert(this.$t("vehicle-acceptance-checkout.error.error-checkout-vehicle")));
    }
  },
  mounted() {
    this.$bus.on(VEHICLE_ACCEPTANCE_OR_CHECKOUT, this.checkout);
  },
  beforeDestroy() {
    this.$bus.off(VEHICLE_ACCEPTANCE_OR_CHECKOUT, this.checkout);
  }
};
</script>
