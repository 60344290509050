<template>
  <b-container fluid>
    <vehicle-checkout />
  </b-container>
</template>

<script>
import VehicleCheckout from "@/components/vehicle/VehicleCheckout.vue";

export default {
  components: {
    "vehicle-checkout": VehicleCheckout
  }
};
</script>
